<template>
  <b-card>
    <action-btn
      :selected-number="selectedNumber"
      :actions="['createBtn', 'editorBtn', 'deleteBtn' , 'reFresh']"
      @add-item="addCommom"
      @delete-item="deleteCommom"
      @refresh-page="refreshPage"
    />
    <good-table
      ref="vgTable"
      class="mt-1 fixed-column"
      :columns="columns"
      :rows="rows"
      :total="total"
      :is-loading="isLoading"
      @column-filter="columnFilter"
      @selected-item="selectedItem"
      @page-change="pageChange"
      @update-item="updateCommom"
      @delete-item="deleteCommomTable"
    >
      <template
        slot="custom-filter"
        slot-scope="props"
      >
        <treeselect
          v-if="props.props.column.field === 'status'"
          v-model="payload.status"
          v-format-v-select
          class="vtreeselect-chooser"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          :options="optionsTrangThai"
          placeholder="Chọn trạng thái"
          :clear-on-select="true"
          :before-clear-all="clearTreeSelect"
          @select="nextTickTreeSelect()"
          @deselect="nextTickTreeSelect()"
        />
      </template>
    </good-table>
    <common-modal
      ref="commonModal"
      :title="title"
      :size="size"
      @handle-ok="handleOk"
      @handle-focus="handleFocusError"
    >
      <component
        :is="componentName === 'XoaForm' ? '' : componentName"
        ref="componentName"
        :data-form="dataForm"
      />
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.checkValidate()"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonnModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonnModal.hideModal(),$refs.commonModal.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonnModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
  </b-card>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import {
  BCard,
  BButton,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import ActionBtn from '@/modules/danh-muc/components/common/ActionBtn.vue'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import GoodTable from '@/components/GoodTable.vue'
import GiayToForm from '@/modules/danh-muc/components/form/GiayToForm.vue'
import { checkStatus, compareObjects } from '@/utils/index'
import { STATUS } from '@/modules/danh-muc/constants/constants'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  components: {
    BCard,
    ActionBtn,
    BButton,
    CommonModal,
    GoodTable,
    GiayToForm,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      title: 'Thêm mới lĩnh vực',
      optionsTrangThai: STATUS,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Mã giấy tờ',
          field: 'maGiayTo',
          width: '130px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên giấy tờ',
          field: 'tenGiayTo',
          thClass: 'text-center',
          width: 'auto',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Trạng thái',
          field: 'status',
          tdClass: 'text-left',
          thClass: 'text-center',
          width: '150px',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          tdClass: 'text-center vertical-align-middle',
          thClass: 'text-center',
          width: '100px',
        },
      ],
      rows: [],
      isShow: false,
      isShowFooter: false,
      selectedItems: [],
      componentName: null,
      total: 0,
      selectedNumber: 0,
      payload: {
        maGiayTo: null,
        tenGiayTo: null,
        moTa: null,
        pageNumber: 1,
        pageSize: 10,
      },
      dataForm: {
        maGiayTo: null,
        tenGiayTo: null,
        banChinh: null,
        banSao: null,
        banPhoto: null,
        moTa: null,
        status: true,
      },
      isLoading: false,
      size: null,
      beginObject: {},
    }
  },
  created() {
    this.getDataGiayTo()
  },
  methods: {
    openModal() {
      if (compareObjects(this.beginObject, this.dataForm)) {
        this.$refs.commonnModal.hideModal()
        this.$refs.commonModal.hideModal()
      } else {
        this.$refs.commonnModal.showModal()
        this.isShowFooter = true
      }
    },
    getDataGiayTo() {
      this.isLoading = false
      try {
        this.$axios.post(END_POINTS.GIAY_TO.DS, this.payload, false).then(res => {
          if (res.data?.succeeded) {
            this.rows = res.data.data
            this.total = res.data.totalCount
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    resetFilter() {
      this.payload = {
        maGiayTo: null,
        tenGiayTo: null,
        moTa: null,
        pageNumber: 1,
        pageSize: 10,
      }
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.getDataGiayTo()
      })
    },
    refreshPage() {
      this.$refs.vgTable.$refs.vbTables.reset()
      this.resetFilter()
      this.getDataGiayTo()
    },
    addCommom() {
      this.isShow = true
      this.title = 'Thêm mới giấy tờ'
      this.componentName = 'GiayToForm'
      this.size = 'lg'
      this.dataForm = {
        maGiayTo: null,
        tenGiayTo: null,
        banChinh: 0,
        banSao: 0,
        banPhoto: 0,
        moTa: null,
        status: true,
      }
      this.$refs.commonModal.showModal()
    },
    selectedItem(data) {
      this.selectedItems = data
      this.selectedNumber = data.length
    },
    handleOk() {
      if (this.componentName === 'XoaForm') {
        const payload = {
          listId: this.selectedItems.map(item => item.id),
        }
        this.$axios.delete(END_POINTS.GIAY_TO.XOA, payload).then(res => {
          if (res.data?.succeeded) {
            if (this.selectedItems.length >= this.rows.length && this.payload.pageNumber > 1) {
              this.payload.pageNumber -= 1
              this.$refs.vgTable.resetCurrentPage(this.payload.pageNumber)
            }
            this.getDataGiayTo()
            this.$refs.commonModal.hideModal()
            if (res.data?.data) {
              res.data.data.forEach(item => {
                if (!item.succeeded) {
                  this.$toast.error(item.message)
                }
              })
            }
          }
        })
      } else if (this.componentName === 'GiayToForm') {
        if (this.dataForm?.id) {
          this.$axios.put(END_POINTS.GIAY_TO.SUA, this.$trimObject(this.dataForm), false).then(res => {
            if (checkStatus(res)) {
              this.getDataGiayTo()
              this.$refs.commonModal.hideModal()
            } else {
              this.$refs.componentName.errorTrungMa = res.data.message
            }
          })
        } else {
          this.$axios.post(END_POINTS.GIAY_TO.THEM, this.$trimObject(this.dataForm), false).then(res => {
            if (checkStatus(res)) {
              this.getDataGiayTo()
              this.$refs.commonModal.hideModal()
            } else {
              this.$refs.componentName.errorTrungMa = res.data.message
            }
          })
        }
      }
    },
    updateCommom(data) {
      this.isShow = true
      this.title = 'Cập nhật giấy tờ'
      this.componentName = 'GiayToForm'
      this.size = 'lg'
      this.dataForm = {
        id: data.id,
        maGiayTo: data.maGiayTo,
        tenGiayTo: data.tenGiayTo,
        banChinh: data.banChinh,
        banSao: data.banSao,
        banPhoto: data.banPhoto,
        moTa: data.moTa,
        status: data.status,
      }
      this.$nextTick(() => {
        this.beginObject = _cloneDeep(this.dataForm)
        this.$refs.commonModal.showModal()
      })
    },
    deleteCommom() {
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa dân tộc đã chọn?'
      this.componentName = 'XoaForm'
      this.size = 'sm'
      this.$refs.commonModal.showModal()
    },
    deleteCommomTable(data) {
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa dân tộc đã chọn?'
      this.componentName = 'XoaForm'
      this.size = 'sm'
      this.selectedItems = [data]
      this.$refs.commonModal.showModal()
    },
    pageChange() {
      const data = this.$refs.vgTable.getCurrentPage()
      this.payload = {
        ...this.payload,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.GIAY_TO.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
        }
      })
    },
    columnFilter(data) {
      this.payload = {
        maDanToc: data.maDanToc ? data.maDanToc.replace(/\s+/g, ' ').trim() : '',
        tenDanToc: data.tenDanToc ? data.tenDanToc.replace(/\s+/g, ' ').trim() : '',
        status: this.payload.status,
        ghiChu: data.ghiChu,
        maGiayTo: data.maGiayTo ? data.maGiayTo.replace(/\s+/g, ' ').trim() : '',
        tenGiayTo: data.tenGiayTo ? data.tenGiayTo.replace(/\s+/g, ' ').trim() : '',
        pageNumber: 1,
        pageSize: this.payload.pageSize,
      }
      this.$axios.post(END_POINTS.GIAY_TO.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
          this.total = res.data.totalCount
          this.$refs.vgTable.resetCurrentPage(1)
        }
      })
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['linhVuc'].includes(field)) {
          this.$refs.componentName.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs.componentName.$refs[field].focus()
        }
      }
    },
  },
}
</script>
<style scoped>
table, td, th {
  border: 1px solid;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 50px;
}
</style>
