<template>
  <giay-to />
</template>
<script>
import GiayTo from '@/modules/danh-muc/components/pages/danh-muc/thu-tuc-hanh-chinh/GiayTo.vue'

export default {
  components: {
    GiayTo,
  },
}
</script>
